import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import { servicesSubPages } from '../../data/pages';
import SEO from '../SEO';
import Container from '../Container';
import Aside from '../Aside';
import ContentArea from '../ContentArea';
import Title from '../Title';
import PageBanner from '../PageBanner';
import AsideNav from '../AsideNav';
import AsideGallery from '../AsideGallery';
import ContentGallery from '../ContentGallery';
import DataCard from '../DataCard';
import ServicesContainer from '../../containers/ServicesContainer';

const Services = ({ page }) => {
  const containerStyled = {
    display: 'flex',
    flexDirection: 'column',
    mb: 10,
  };
  const pageTitleStyled = {
    color: 'grays.4',
    display: ['block', null, null, 'none', null],
    fontFamily: 'tertiary',
    fontSize: 9,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '35px',
    mb: 5,
    mt: 5,
    textTransform: 'uppercase',
  };
  const dataCardWrapperStyled = {
    columnCount: ['1', null, '2', null, null],
    columnGap: [null, '30px', null, null, '120px'],
    flex: 'none',
    width: '100%',
  };
  const ContentAreaStyled = {
    mb: -5,
    ml: ['auto', null, null, null, null],
    mr: 0,
    width: ['100%', null, null, 'calc(100% - 320px)', 'maxWidths.md'],
  };
  return (
    <ServicesContainer>
      {(servicesData) => {
        const pageName = page || 'cost_estimating_analyses_and_controls';
        const detailInfo = servicesData
          .map((ele) => {
            return ele.id === pageName ? ele : null;
          })
          .find((el) => el);
        return (
          <Box>
            <Box>
              <SEO />
              {/* <SEO description="" title={`${detailInfo.title} | Services`} /> */}
              <PageBanner image={detailInfo.bannerImage} />
              <Container sx={containerStyled}>
                <Title
                  sx={{
                    ...pageTitleStyled,
                    display: ['none', null, null, 'block', null],
                    ml: 'auto',
                    mt: '55px',
                    width: [
                      '100%',
                      null,
                      null,
                      'calc(100% - 320px)',
                      'maxWidths.md',
                    ],
                  }}
                >
                  {detailInfo.title}
                </Title>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: ['column', null, null, 'row', null],
                  }}
                >
                  <Aside>
                    {servicesSubPages.length > 0 && (
                      <AsideNav
                        links={servicesSubPages}
                        linksSx={{ fontWeight: 'regular' }}
                        specialLinks={['construction economics']}
                        title="Services"
                      />
                    )}
                    {detailInfo.relatedInfo &&
                      detailInfo.relatedInfo.length > 0 && (
                        <AsideGallery items={detailInfo.relatedInfo} />
                      )}
                  </Aside>
                  <ContentArea sx={ContentAreaStyled}>
                    <Title sx={pageTitleStyled}>{detailInfo.title}</Title>
                    {detailInfo.intro.points && (
                      <DataCard
                        details={detailInfo.intro.points}
                        paras
                        title={detailInfo.intro.subtitle}
                        titleSx={{ fontSize: 4 }}
                      />
                    )}
                    <Box sx={dataCardWrapperStyled}>
                      {detailInfo.content &&
                        detailInfo.content.length > 0 &&
                        detailInfo.content.map((dataList, index) => {
                          if (dataList.points && dataList.points.length > 0) {
                            return (
                              <DataCard
                                key={`data-card-${index}`}
                                details={dataList.points}
                                title={dataList.subtitle}
                                titleSx={{ fontSize: 4 }}
                              />
                            );
                          }
                          return null;
                        })}
                    </Box>
                  </ContentArea>
                </Box>
              </Container>
              {detailInfo.testimonials &&
                detailInfo.testimonials.length > 0 && (
                  <ContentGallery items={detailInfo.testimonials} />
                )}
            </Box>
          </Box>
        );
      }}
    </ServicesContainer>
  );
};

Services.propTypes = {
  page: PropTypes.string,
};

Services.defaultProps = {
  page: '',
};

export default Services;
