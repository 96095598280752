import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const ServicesContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allServicesJson {
          edges {
            node {
              id
              bannerImage
              content {
                points {
                  point
                }
                subtitle
              }
              intro {
                points {
                  point
                }
                subtitle
              }
              title
              relatedInfo {
                image
                text
              }
              testimonials {
                author
                image
                quote
              }
            }
          }
        }
      }
    `}
    render={({ allServicesJson: { edges: servicesData } }) =>
      children(
        servicesData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

ServicesContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ServicesContainer;
