import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Banner from '../Banner';

const AsideGallery = ({ wrapperSX, items, itemSx, textSx }) => {
  const galleryStyled = {
    display: 'flex',
    flex: 'auto',
    flexDirection: ['column', null, 'row', 'column', null],
    flexWrap: [null, null, 'wrap', 'unset', null],
    justifyContent: 'space-between',
    mx: -2,
    ...wrapperSX,
  };
  const itemStyled = {
    flex: ['1', null, null, 'none', null],
    mt: 'auto',
    mx: 2,
    pt: '40px',
    ...itemSx,
  };
  const textStyled = {
    color: 'grays.10',
    fontFamily: 'tertiary',
    fontSize: 2,
    fontStyle: 'normal',
    fontWeight: 'regular',
    lineHeight: '30px',
    mt: 2,
    textAlign: 'center',
    ...textSx,
  };
  return (
    <Box sx={galleryStyled}>
      {items.map((item, index) => (
        <Box key={`gallery-${index}`} sx={itemStyled}>
          <Banner image={item.image} imageSx={{ width: '100%' }} />
          {item.text && <Box sx={textStyled}>{item.text}</Box>}
        </Box>
      ))}
    </Box>
  );
};

AsideGallery.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      text: PropTypes.string,
    })
  ).isRequired,
  itemSx: PropTypes.shape({}),
  textSx: PropTypes.shape({}),
  wrapperSX: PropTypes.shape({}),
};

AsideGallery.defaultProps = {
  itemSx: {},
  textSx: {},
  wrapperSX: {},
};

export default AsideGallery;
