import React from 'react';
import { Router, Redirect } from '@reach/router';
import NotFoundPage from './404';
import Services from '../components/Services';
import { servicesData } from '../data/servicesData';

const ServicesRouter = (props) => (
  <Router>
    {servicesData &&
      Object.keys(servicesData).map((page) => {
        return (
          <Services
            key={`service-${page}`}
            {...props}
            page={page}
            path={`/services/${page}/`}
          />
        );
      })}
    <Redirect
      from="/services/"
      noThrow
      to="/services/cost_estimating_analyses_and_controls"
    />
    <NotFoundPage default />
  </Router>
);

export default ServicesRouter;
