import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Container from '../Container';
import ContentArea from '../ContentArea';
import Banner from '../Banner';

const ContentGallery = ({
  authorSx,
  items,
  itemSx,
  itemImageSx,
  itemInnerSx,
  itemWrapper,
  itemQuoteSx,
  quoteTextSx,
  wrapperInnerSx,
  wrapperSx,
}) => {
  const wrapperStyled = {
    bg: 'grays.0',
    pb: 4,
    pt: '40px',
    ...wrapperSx,
  };
  const wrapperInnerStyled = {
    alignItems: ['center', null, null, 'unset', null],
    display: 'flex',
    flexDirection: 'column',
    maxWidth: ['100%', null, null, 'maxWidths.content', null],
    ...wrapperInnerSx,
  };
  const itemWrapperStyled = {
    display: 'flex',
    flexDirection: ['column', null, 'row', null, null],
    justifyContent: 'space-between',
    maxWidth:
      items.length > 2
        ? ['100%', null, null, 'maxWidths.content', null]
        : ['100%', null, null, 'calc(100% - 320px)', 'maxWidths.md'],
    ml: [-2, null, null, 'auto', null],
    mr: [-2, null, null, '0', null],
    ...itemWrapper,
  };
  const itemStyled = {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    maxWidth: items.length > 2 ? '270px' : '350px',
    mx: ['auto', null, null, 0, null],
    px: [2, null, null, 0, null],
    ...itemSx,
  };
  const itemInnerStyled = {
    maxWidth: ['100%', null, '270px', null, null],
    mb: 4,
    ...itemInnerSx,
  };
  const itemImageStyled = {
    maxWidth: ['100%', null, '350px', null, null],
    width: '100%',
    ...itemImageSx,
  };
  const itemQuoteStyled = {
    fontFamily: 'tertiary',
    fontSize: 2,
    lineHeight: '28px',
    mt: 5,
    ...itemQuoteSx,
  };
  const quoteTextStyled = {
    ' > span': {
      color: 'warning',
    },
    color: 'grays.4',
    fontFamily: 'tertiary',
    fontSize: 2,
    fontStyle: 'normal',
    fontWeight: 'regular',
    lineHeight: '28px',
    ...quoteTextSx,
  };
  const authorStyled = {
    color: 'grays.9',
    fontFamily: 'tertiary',
    fontSize: 2,
    fontWeight: 'medium',
    lineHeight: '28px',
    ...authorSx,
  };
  return (
    <Box sx={wrapperStyled}>
      <Container sx={wrapperInnerStyled}>
        <ContentArea sx={itemWrapperStyled}>
          {items.map((item, index) => (
            <Box key={`content-gallery-${index}`} sx={itemStyled}>
              <Box sx={itemInnerStyled}>
                {item.image && (
                  <Banner image={item.image} imageSx={itemImageStyled} />
                )}
                {/* {item.quote && item.quote.length > 0 && (
                  <Box sx={itemQuoteStyled}>
                    <Box sx={quoteTextStyled}>
                      {item.quote.map((text, index) => (
                        <p key={`para-${index}`}>{text}</p>
                      ))}
                    </Box>
                    {item.author && (
                      <Box sx={authorStyled}>
                        <em>- {item.author}</em>
                      </Box>
                    )}
                  </Box>
                )} */}
                {item.quote && (
                  <Box sx={itemQuoteStyled}>
                    <Box sx={quoteTextStyled}>
                      <span>&quot;</span>
                      {item.quote}
                      <span>&quot;</span>
                    </Box>
                    {item.author && (
                      <Box sx={authorStyled}>
                        <em>- {item.author}</em>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </ContentArea>
      </Container>
    </Box>
  );
};

ContentGallery.propTypes = {
  authorSx: PropTypes.shape({}),
  itemImageSx: PropTypes.shape({}),
  itemInnerSx: PropTypes.shape({}),
  itemQuoteSx: PropTypes.shape({}),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      image: PropTypes.string,
      quote: PropTypes.string,
    })
  ).isRequired,
  itemSx: PropTypes.shape({}),
  itemWrapper: PropTypes.shape({}),
  quoteTextSx: PropTypes.shape({}),
  textSx: PropTypes.shape({}),
  wrapperInnerSx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

ContentGallery.defaultProps = {
  authorSx: {},
  itemImageSx: {},
  itemInnerSx: {},
  itemQuoteSx: {},
  itemSx: {},
  itemWrapper: {},
  quoteTextSx: {},
  textSx: {},
  wrapperInnerSx: {},
  wrapperSx: {},
};

export default ContentGallery;
